<template>
  <div>
    <!-- pic1B254EFE8E6F6F7AD563C9A6D28F59B3.jpg -->
    <img class="rounded h-265" src="">
          <p>
      <font size="4">Welcome</font> to part 6 of this special series on building the best creations in LEGO Universe! Lead animator Erik Beyer returns in this edition to teach us about the benefits of flexibility in bringing virtual LEGO models to life.
    </p>
    <p>
      Erik points out that some of the biggest challenges of animating LEGO creations come from the bricks’ rigidness. The same solidity that makes the bricks so perfect for building can make the movements of animated LEGO models and minifigures look awfully stiff.
    </p>
    <p>
      That’s why one of the first tasks the LEGO Universe animation team took on was to work out how expressive they could make rigid models. Erik says, “We were trying to see how much we could do with very little flexibility--- and we really gave it a good go.”
    </p>
    <p>
      That exercise resulted in animations like this one:
    </p>
    <!-- MISSING: UniverseNewsNetworkFlashArticleItems: downloadD8645F12DB1ECF695E9E9395BF363C17.swf -->
    <img class="rounded h-336" src="">
    <p>
      See how the above battle mech’s movements are only sort of scary? The machine’s motions are so rigid that opponents might easily avoid its attacks or find openings in its defenses!
    </p>
    <p>
      Erik says limitations like that one have led the LEGO Universe art team to make parts of their animated models more flexible. Just look at how much more menacing the mech is when its motions are fluid.
    </p>
    <!-- MISSING: UniverseNewsNetworkFlashArticleItems: downloadBC24E54774B0F301A19354807EA06835.swf -->
    <img class="rounded h-336" src="">
    <p>
      This machine’s movements get more of your adrenaline flowing because it looks challenging to beat-- and that makes for more LEGO Universe fun!
    </p>
    <p>
      Stay tuned for the final installment of this special series! You’ll see LEGO Universe minifigures taking on our mech in battle!
    </p>
    <p>
      <strong>This edition’s LEGO Universe building tips:</strong>
      <br>• Experiment to see how far you can push a creation.
      <br>• Focus on fun when creating a character!
    </p>
    <p>
      <em>Follow these links if you missed earlier editions of this special series!</em><br>
      <router-link :to="{ name: 'story', params: {id: 82873} }">
        Creating digital designs the LEGO Universe way: Part 1
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 88803} }">
        Creating digital designs the LEGO Universe way: Part 2
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 91070} }">
        Creating digital designs the LEGO Universe way: Part 3
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 107581} }">
        Creating digital designs the LEGO Universe way: Part 4
      </router-link><br>
      <router-link :to="{ name: 'story', params: {id: 110972} }">
        Creating digital designs the LEGO Universe way: Part 5
      </router-link>
    </p>
  </div>
</template>
